* {
    margin: 0;
    padding: 0;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 450;
}

nav {
    background-color: var(--primary-color);
    color: var(--light-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem
}

.nav-title {
    font-size: 2rem;
    color: var(--light-color);
    text-decoration: none;
}

nav ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

nav ul li {
    font-size: 1.4rem;
    display: inline-block;
    list-style-type: none;
}

nav ul li a {
    color: inherit;
    text-decoration: none;
    padding: 1rem;
}

nav li:hover {
    color: var(--secondary-color);
    transition: .3s;
}

.mobile-navigation{
    display: none;
}

@media (max-width: 800px){

    .desktop-navigation{
        display: none;
    }

    .mobile-navigation{
        display: flex;
        align-items: center;
        height: 10vh;
    }

    nav ul{
        border-top: solid 1px var(--secondary-color);
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        position: absolute;
        top: 12.8vh;
        right: 0;
        height: 45vh;
        width: 100%;
        z-index: 101;
        background-color: var(--primary-color);
        transition: height 0.15s ease-out;
    }
}

.HamburgerMenu{
    position: absolute;
    right: 5%;
    cursor: pointer;
    color: var(--light-color);
}