.footer-view {
    height: 3.4rem;
    width: 100%;
    background-color: var(--primary-color);
    min-height: 3.4rem;
}

.footer-text {
    color: var(--light-color);
    font-size: 20px;
    text-align: center;
}