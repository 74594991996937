:root {
    --light-color: #FFF2F1;
    --tertiary-color: #e1e1f3;
    --secondary-color: rgb(169, 162, 241);
    --primary-color: #5F00BA;
}

.bg-color {
    background-color: var(--light-color);
}

.field {
    background-color: var(--tertiary-color);
    border-radius: 10px;
    text-align: center;
    padding: 5px 30px;
}

.edit-view {
    background-color: #e2e2ff;
    border-radius: 10px;
    text-align: center;
    padding: 20px 30px;
}

.create-view {
    background-color: #e2e2ff;
    border-radius: 10px;
    text-align: center;
    padding: 10px 0px;
    width: 75%;
    margin: auto;
}

.text-color {
    color: var(--light-color);
    text-decoration: none;
}

.margin-top {
    margin-top: 3rem;
}

.flex-edit-field {
    display: flex;
    margin: 10px;
    background-color: #e2e2ff;

    flex-direction: column;
    @media (min-width: 1000px) {
        flex-direction: row;
        justify-content: space-between;
    }
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.circle-direction {
    padding: 0.7rem;
    display: flex;
    flex-direction: column;
    @media (min-width: 600px) {
        flex-direction: row;
        gap: 1rem;
    }
}

.login-area {
    padding: 2rem;
    margin: 3rem;
    border-radius: 20px;
}

.field-gap {
    gap: 0.6rem;
}

.login-form {
    width: 18rem;
    background-color: var(--tertiary-color);
}

.delete-button {
    background-color: rgb(255, 100, 100);
    width: min-content;
    width: 100%;
    border-radius: 10px;
    border: none;
    font-size: 14px;
    &:hover {
        background-color: rgb(255, 50, 50);
    }
}

body {
    height: 100%;
    width: 100%;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    background-color: var(--light-color);
    padding: 0; 
}

.container {
    width: 40rem;
    gap: 1rem;
}

.padding-m {
    padding: 1rem;
}

.text-left {
    text-align: left;
}

.left {
    justify-content: left;
    align-items: flex-start;
}

.right {
    justify-content: right;
    align-items: flex-start;
}

.between {
    justify-content: space-between;
}

.flex-gap {
    gap: 3rem;
}

.grid-columns {
    display: grid;
    grid-template-columns: repeat(3, 40%);
}

.fill-width {
    width: 100%;
    margin: auto;
    justify-content: center;

    @media (min-width: 600px) {
        width: 60%;
    };

}

.fill-width-edit {
    width: 100%;
    margin: auto;
    justify-content: center;

    @media (min-width: 600px) {
        width: 75%;
    };
}

.box {
    display: flex;
    flex-flow: column;
    height: 100%;
}

html,
body {
    height: 100%;
}

.small-gap {
    gap: 10px;
}

.fill-height {
    height: 100%;
}

.m-margin {
    margin: 10px;
}

.small-width {
    width: min-content;
    margin: 1rem;
}

.overlay {
     position: absolute;
     z-index: 1;
}

.marker {
    cursor: pointer;
}

.question-row {
    margin: 1rem;
    text-align: center;
}